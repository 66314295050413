import React from 'react';
import ReactDOM from "react-dom";
import {  Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';


import Router from './routes/Routes';

function App() {
    return (
            <BrowserRouter>
                <div>
                    <Router />
                </div>
            </BrowserRouter>

    );
}
export default App;

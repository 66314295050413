//Importing Action types:
import axios from "axios";
import { FormActionTypes } from "./formActionTypes";
const baseUrl = process.env.REACT_APP_SERVER_URL;

//Actions or Events:
export const setInvest = (invest) => {
  return {
    type: FormActionTypes.SET_INVEST,
    payload: invest,
  };
};

export const setInvestAmount = (investAmount) => {
  return {
    type: FormActionTypes.SET_INVESTAMOUNT,
    payload: investAmount,
  };
};
export const setProducts = (products) => {
  return {
    type: FormActionTypes.SET_PRODUCTS,
    payload: products,
  };
};

export const setAdvice = (advice) => {
  return {
    type: FormActionTypes.SET_ADVICE,
    payload: advice,
  };
};

export const setEmail = (email) => {
  return {
    type: FormActionTypes.SET_EMAIL,
    payload: email,
  };
};

export const setName = (name) => {
  return {
    type: FormActionTypes.SET_NAME,
    payload: name,
  };
};

export const setSurname = (surname) => {
  return {
    type: FormActionTypes.SET_SURNAME,
    payload: surname,
  };
};

export const setPhone = (phone) => {
  return {
    type: FormActionTypes.SET_PHONE,
    payload: phone,
  };
};
export const setPostCode = (postCode) => {
  return {
    type: FormActionTypes.SET_POSTCODE,
    payload: postCode,
  };
};
export const setHouseNo = (houseNo) => {
  return {
    type: FormActionTypes.SET_HOUSENO,
    payload: houseNo,
  };
};

export const SendStateToCompare = async (state) => {
  try {
    let res = await axios.post(baseUrl + "/users/user-input", state);
    console.log(res.data);
  } catch (error) {
    console.log("error", error);
  }
};
export const getFilteredProducts = async (type) => {
  try {
    let res = await axios.get(baseUrl + "/products/filter", {
      params: { type: type },
    });
    return res.data;
    
  } catch (error) {
    console.log("couldn't find products or something", error);
  }
};

export const SendfullState = async (state) => {
  try {
    let res = await axios.post(baseUrl + "/companies/all-company-data", state);
    console.log(res.data);
  } catch (error) {
    console.log("error", error);
  }
};
export const getLimits = async () => {
  try {
    let res = await axios.get(baseUrl + "/products/limits");
    console.log(res.data[0]);
    return res.data[0];
  } catch (error) {
    console.log(error);
  }
};

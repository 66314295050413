//React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
//MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Checkbox, FormControlLabel, FormGroup, FormHelperText, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//Styles
import './Styles.css';
//Form builders & validators
import { useFormik } from 'formik';
import * as yup from 'yup';
import { setProducts } from '../../services/Form/FormActions';
//actions



export const Step3 = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [products, setProductsValue] = useState({
        stocks: false,
        assets: false,
        assurance: false,
        insurance: false,
        no: false
    });
    const [open, setOpen] = useState(false);


    //navigation
    const location = useLocation();
    // console.log(location.state);
    useEffect(() => {
        if (location.state !== 'fromStep2') {
            //step2 should only be accessible if i come from step1!
            //otherwise, go back to step1.
            Navigate(-1)
        }
    }, []);
    let locState = "fromStep3"

    //validators and form building
    const validationSchema = yup.object({
        products: yup
            .string()
            .required("Selecteer minimaal één optie")

    })

    const formik = useFormik({
        initialValues: {
            products,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            //action :

            Navigate("../step/4", { state: locState })
        },
    })

    //State Management:
    useEffect(() => {

        var data = window.sessionStorage.getItem("products");
        if (data) {
            data = JSON.parse(data)
            formik.setFieldValue("products", data)
            setProductsValue({ ...data });
            dispatch(setProducts(data)) 
            // console.log("products name after get", data);
        }


    }, []);
    useEffect(() => {
        if (Object.keys(products).filter((v) => v).length !== 0) {
            window.sessionStorage.setItem("products", JSON.stringify(products))
            formik.setFieldValue("products", window.sessionStorage.getItem("products"))
        }

    }, [products]);


    //Choices:
    const { stocks, assets, assurance, insurance, no } = products
    const error = [stocks, assets, assurance, insurance, no].filter((v) => v).length == 0;

    //event handling
    const Next = (e) => {
        e.preventDefault()
        formik.handleSubmit()
    }


    // console.log(products);

    const onBack = (e) => {
        e.preventDefault()
        Navigate(-1)
    }

    const handleChange = (e) => {
        setProductsValue({
            ...products,
            [e.target.name]: e.target.checked,
        })
        console.log({
            ...products,
            [e.target.name]: e.target.checked,
        });
        dispatch(setProducts({
            ...products,
            [e.target.name]: e.target.checked,
        }))
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Grid container
            direction="column"
            marginTop="60px"
        >
            <Box>
                <div className='back-btn' onClick={onBack}>
                    <IconButton  ><ArrowBackIos /></IconButton>
                </div>
                <Card sx={{
                    minHeight: '250px',
                    minWidth: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'none',
                    flexDirection: 'column',
                    backgroundColor: '#F7F7F7'

                }}>
                    <React.Fragment>
                        <CardContent >
                            <div>

                                <FormControl variant='filled' className='centerMyForm'>
                                    <Typography
                                        component="div"
                                        variant='h3'
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: "Merriweather  serif",
                                            fontSize: '2.1rem',
                                            lineHeight: '36px',
                                            textAlign: 'center',
                                            marginBottom: "30px"


                                        }}
                                    >
                                        Met welke producten heb je  ervaring?
                                    </Typography>
                                    <IconButton color="primary" aria-label="question" component="span"
                                        onClick={handleClickOpen}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"waarom deze vraag:"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">

                                                Ervaring is belangrijk omdat je een bepaalde kennis nodig hebt voor sommige producten.
                                                <br />
                                                Het is mogelijk om kennis te halen bij een externe
                                                adviseur.
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>

                                            <Button onClick={handleClose} autoFocus>
                                                OK
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <br />
                                    <FormControl variant='filled' className='centerMyForm'
                                        required
                                        error={error}
                                        component="fieldset"
                                    >
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={stocks} onChange={handleChange} name="stocks" />
                                                }
                                                label="Beleggen in aandelen, fondsen of ETF’s"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={assets} onChange={handleChange} name="assets" />
                                                }
                                                label="Vermogensbeheer"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={assurance} onChange={handleChange} name="assurance" />
                                                }
                                                label="Beleggen via een verzekering of lijfrente"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={insurance} onChange={handleChange} name="insurance" />
                                                }
                                                label="Beleggen met advies"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={no} onChange={handleChange} name="no" />
                                                }
                                                label="Geen"
                                            />
                                        </FormGroup>
                                        {error ? <FormHelperText>Selecteer minimaal één optie.</FormHelperText> : <></>}

                                        <br />
                                        <div className='buttonbox'>
                                            <Button variant="contained" type='submit' size="large"
                                                className='button'
                                                disabled={error}
                                                data-testid="nextButton"
                                                onClick={Next}  >Verder</Button>
                                        </div>
                                    </FormControl>
                                </FormControl>
                            </div>
                        </CardContent>
                    </React.Fragment>
                </Card>
            </Box>
        </Grid>
    );

}
//importing action types
import { FormActionTypes } from "./formActionTypes";

const initialState = {
  //Form state
  Invest: "",
  investAmount: "",
  products: {
    stocks: false,
    assets: false,
    assurance: false,
    insurance: false,
    no: false,
  },
  Advice: false,

  Email: "",
  Phone: "",
  Name: "",
  Surname: "",
  HouseNo: "",
  PostCode: "",
};

export const InfoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FormActionTypes.SET_INVEST:
      return { ...state, Invest: payload };

    case FormActionTypes.SET_INVESTAMOUNT:
      return { ...state, investAmount: payload };

    case FormActionTypes.SET_PRODUCTS:
      return { ...state, products: payload };

    case FormActionTypes.SET_ADVICE:
      return { ...state, Advice: payload };

    case FormActionTypes.SET_EMAIL:
      return { ...state, Email: payload };

    case FormActionTypes.SET_PHONE:
      return { ...state, Phone: payload };

    case FormActionTypes.SET_NAME:
      return { ...state, Name: payload };

    case FormActionTypes.SET_SURNAME:
      return { ...state, Surname: payload };

    case FormActionTypes.SET_HOUSENO:
      return { ...state, HouseNo: payload };

    case FormActionTypes.SET_POSTCODE:
      return { ...state, PostCode: payload };

    default:
      return state;
  }
};

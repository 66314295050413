//React
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
//MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FormControlLabel, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
//Form builders & validators
import { useFormik } from 'formik';
import * as yup from 'yup';
//actions
import { setAdvice } from '../../services/Form/FormActions';
//styles
import './Styles.css';

export const Step4 = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [advice, setAdviceV] = useState(false)
    const [open, setOpen] = useState(false);
    const [pass, setPass]=useState(false)
  
    //navigation
    const location = useLocation();
    // console.log(location.state);
    useEffect(() => {
        if (location.state !== 'fromStep3') {
            //step2 should only be accessible if i come from step1!
            //otherwise, go back to step1.
            Navigate(-1)
        }
    }, []);
    let locState = "fromStep4"

    //form builder and validators
    const validationSchema = yup.object({
        advice: yup
            .string('Maak een keuze')
            .required('Maak een keuze')
    })
    const formik = useFormik({
        initialValues: {
            advice: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

        },
    })

    //State Management:
    //To make sure that state is persistant on refresh.
    useEffect(() => {
        const data = window.sessionStorage.getItem("advice");
    
        formik.setFieldValue("advice", data)
        dispatch(setAdvice(data))

    }, []); 
    //OnChange:
    useEffect(() => {
        if(advice!==""){
            window.sessionStorage.setItem("advice", advice)
            dispatch(setAdvice(advice))
        }
        
    }, [advice]);

    //event handling
    const Next = (e) => {
        e.preventDefault()
        formik.handleSubmit()
        Navigate("../step/5", { state: locState })
    }
    const handleChange = (e) => {
        e.preventDefault()
        if(e.target.value=='yes'){setAdviceV(true)}
        else{setAdviceV(false)}
        setPass(true)

    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    // console.log("clients after useState", clients);

    const onBack = (e) => {
        e.preventDefault()
        Navigate(-1)
    }
    return (
        <Grid container
            direction="column"
            marginTop="60px"
        >
            <Box>
                <div className='back-btn' onClick={onBack}>
                    <IconButton  ><ArrowBackIos /></IconButton>
                </div>
                <Card sx={{
                    minHeight: '250px',
                    minWidth: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'none',
                    flexDirection: 'column',
                    backgroundColor: '#F7F7F7'

                }} >
                    <React.Fragment>
                        <CardContent >
                            <div>
                                <FormControl variant='filled' className='centerMyForm'>
                                    <Typography
                                        component="div"
                                        variant='h3'
                                        sx={{
                                            fontWeight: 500,
                                            fontFamily: "Merriweather  serif",
                                            fontSize: '2.1rem',
                                            lineHeight: '36px',
                                            textAlign: 'center',
                                            marginBottom: "30px"

                                        }}
                                    >
                                        Stelling:   <br />
                                        Ik vind duurzaam beleggen belangrijker dan rendement behalen.                                    </Typography>
                                    <IconButton color="primary" aria-label="question" component="span"
                                        onClick={handleClickOpen}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"waarom deze vraag:"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                De meeste vermogensbeheerders zijn vooral op zoek naar rendement en duurzaamheid komt pas op de tweede plaats.
                                                <br />
                                                Sommige beheerders starten wel vanuit duurzaamheid, en accepteren hierbij een lager rendement.   <br />
                                                Het rendement is lager omdat er maar weinig zeer duurzame bedrijven bestaan en duurzaamheid geld kost.
                                                <br />
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>

                                            <Button onClick={handleClose} autoFocus>
                                                OK
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <br />
                                    <FormControl variant='filled' className='centerMyForm'  >
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="advice"
                                            id='advice'
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="yes"
                                                control={<Radio  />} label="Ja"
                                            />
                                            <FormControlLabel value="no"
                                                control={<Radio />} label="Nee"
                                            />

                                        </RadioGroup>

                                        <br />
                                        <div className='buttonbox'>
                                            <Button variant="contained"
                                                type='submit'
                                                size="large"
                                                className='button'
                                                disabled={!pass}
                                                data-testid="nextButton"
                                                onClick={Next}  >Verder</Button>
                                        </div>
                                    </FormControl>
                                </FormControl>
                            </div>

                        </CardContent>
                    </React.Fragment>
                </Card>
            </Box>
        </Grid>
    );

}
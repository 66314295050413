//React
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
//MUI tools
import Button from '@mui/material/Button';
import { CardMedia } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
//styles
import logo from '../../assets/logo.webp'
import './style.css';
import aboutus_image from '../../assets/img/shutterstock_1446278705.webp';
import introcontent_image from '../../assets/img/shutterstock_719471299.webp';

export const Homepage = () => {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    const Navigate = useNavigate();
    const Contact=(e)=>{

        e.preventDefault();
        Navigate("/contact")

    }


    const Next = (e) => {
        e.preventDefault()
        Navigate("../step/1")
    }
    return (


        <Grid container direction="column">
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" style={{ background: "#F7F7F7", boxShadow: "none", borderBottom: '1px solid rgb(139, 213, 255)' }}>
                    <Toolbar>
                        <Box sx={{ flexGrow: 1 }}>
                            <div></div>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <a href="..">
                                <img  rel="preload" className='logo-header' src={logo} alt="logo" />
                            </a>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>

            <Grid container className="introcontent-background" spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} >
                <Grid container className="introcontent"spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} >
                    <Grid item xs={6} className="introcontent_mobile-img">
                        <CardMedia  rel="preload"
                            className='startimg'
                            component="img"
                            image={introcontent_image}
                            alt="business logo img"

                        />
                    </Grid>
                    <Grid item xs={6} className="introcontent-text">

                        <h1>Vergelijk vermogensbeheerders en bepaal welke bij je past.

                        </h1>
                        <Button onClick={Next} variant="contained">Begin met vergelijken</Button>


                    </Grid>
                    <Grid item xs={6} className="introcontent_desktop-img">
                        <CardMedia  rel="preload"
                            className='startimg'
                            component="img"
                            image={introcontent_image}
                            alt="business logo img"

                        />
                    </Grid>
                </Grid>
            </Grid>


            <Grid container className="linkscontent" spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>


                {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={2} sm={4} md={4} >
                      <div className="boxcontent-left">
                          <h2>Advies</h2>
                          <h4>We kunnen je als bankenvergelijking
                              helpen in je keuze met beleggen
                              .</h4>
                          <Button onClick={Next} variant="contained">Neem contact met ons op</Button>

                      </div>
                  </Grid>
              <Grid item xs={2} sm={4} md={4} >
                  <div className="boxcontent-right">
                      <h2>Vermogensbeheer</h2>
                      <h4>Laat je geld beleggen beheren door een vermogensbeheerder. In vier vragen bepalen we welke vermogensbeheerder bij je past.</h4>
                      <Button onClick={Next} variant="contained">Vermogensbeheer vergelijken</Button>

                  </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4} >
                  <div className="boxcontent-left">
                      <h2>Laten beleggen</h2>
                      <h4>Laat je geld automatisch beleggen. Je maakt het geld over en de aanbieder doet de rest. Je doorloopt eerst een vragenlijst om je beleggingsplan op te stellen.
                      </h4>
                      <Button onClick={Next} variant="contained">laten beleggen vergelijken</Button>

                  </div>
          </Grid>
              <Grid item xs={2} sm={4} md={4} >
                  <div className="boxcontent-right">
                      <h2>Zelf beleggen</h2>
                      <h4>Vergelijk alle brokers waarmee jezelf op de beurs kunt beleggen. Je dient zelf individuele orders te plaatsen.</h4>
                      <Button onClick={Next} variant="contained">zelf beleggen vergelijken</Button>

                  </div>
              </Grid>
          </Grid>*/}


                <Grid item xs={6}>
                    <div className="boxcontent-left">
                        <h2>Advies</h2>
                        <h4>We kunnen je als bankenvergelijking helpen in je keuze met beleggen.
                        </h4>
                        <Button onClick={Contact} variant="contained">Neem contact met ons op</Button>

                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="boxcontent-right">
                        <h2>Vermogensbeheer</h2>
                        <h4>Laat je geld beleggen beheren door een vermogensbeheerder. In vier vragen bepalen we welke vermogensbeheerder bij je past.</h4>
                        <Button onClick={Next} variant="contained">Vermogensbeheer vergelijken</Button>

                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="boxcontent-left">
                        <h2>Laten beleggen</h2>
                        <h4>Laat je geld automatisch beleggen. Je maakt het geld over en de aanbieder doet de rest. Je doorloopt eerst een vragenlijst om je beleggingsplan op te stellen.
                        </h4>
                        <Button onClick={Next} variant="contained">laten beleggen vergelijken</Button>

                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="boxcontent-right">
                        <h2>Zelf beleggen</h2>
                        <h4>Vergelijk alle brokers waarmee jezelf op de beurs kunt beleggen. Je dient zelf individuele orders te plaatsen.</h4>
                        <Button variant="contained" >
                            <a href="https://www.bankenvergelijking.nl/beleggen/zelf-beleggen/">zelf beleggen vergelijken</a>
                        </Button>

                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} className="aboutuscontent">
                <Grid item xs={6}>
                    <h2>Hoe bankenvergelijking werkt</h2>
                    <h4>We stellen een aantal vragen, om te bepalen welke vorm van beleggen bij je past. Hieruit volgt een lijst van mogelijk interessante aanbieders.</h4>

                </Grid>
                <Grid item xs={6}>
                    <CardMedia  rel="preload"
                        className='startimg'
                        component="img"
                        image={aboutus_image}
                        alt="business logo img"

                    />
                </Grid>
            </Grid>
        </Grid>





    );
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./Step/Styles.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { setOffer } from "../services/Card/CardActions";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { object } from "prop-types";

export const InvestProvider = (props) => {
  const {
    logo,
    name,
    product_link,
    type,
    jaarlijkse_kosten,
    min_deposit,
    description: { product, def, ...disc },
    ...rest
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const Navigate = useNavigate();
  let locState = "fromStep5or8";

  let n = "https://www.bankenvergelijking.nl/".length;
  let proLink = props.product_link;
  let finalLink = proLink.slice(0, n) + "red.php?s=" + proLink.slice(n);

  const Next = (e) => {
    // dispatch(setOffer(logo, name));
    window.sessionStorage.setItem("logo", logo);
    window.sessionStorage.setItem("product", name);
    window.sessionStorage.setItem("deposit", min_deposit);
    window.sessionStorage.setItem("jaarlijkse_kosten", jaarlijkse_kosten);

    window.open(finalLink);
  };
  // console.log(location.state);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        direction="column"
        sx={{
          // width: "590px",
          maxWidth: 700,
          minHeight: 200,
          alignItems: "center",
          justifyContent: "center",
        }}
        className="container"
      >
        <Card
          className="container"
          sx={{
            // minWidth: 500,
            maxWidth: 700,
            minHeight: 200,
            // display:'flex',
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "135px" }}>
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={logo}
              alt="provider logo"
              style={{
                width: "100px",
                marginLeft: "10px",
              }}
            />
          </div>
          <div style={{ width: "200px", marginLeft: "9px" }}>
            <Box>
              <div style={{ height: "50px" }}>
                <Typography
                  component="div"
                  variant="subtitle"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    fontSize: 20,
                  }}
                >
                  <strong>{name}</strong> <br />
                </Typography>{" "}
                <br />
              </div>
            </Box>

            <div>
              <Typography
                component="div"
                variant="subtitle"
                color="#2E353C"
                sx={{ fontSize: 13 }}
              >
                <strong
                  style={{
                    fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                  }}
                >
                  Type:
                </strong>{" "}
                &nbsp;
                {type}
              </Typography>
            </div>
            <div>
              <Typography
                component="div"
                variant="subtitle"
                color="#2E353C"
                sx={{ fontSize: 13 }}
              >
                <strong
                  style={{
                    fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                  }}
                >
                  Jaarlijkse kosten: &nbsp;
                </strong>
                {jaarlijkse_kosten}%
              </Typography>
            </div>

            <div>
              <Typography
                component="div"
                variant="subtitle"
                color="#2E353C"
                sx={{ fontSize: 13 }}
              >
                <strong
                  style={{
                    fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                  }}
                >
                  Minimale inleg: &nbsp;
                </strong>
                € {min_deposit}
              </Typography>
            </div>
          </div>
          <div style={{ width: "210px", margin: "auto" }}>
            <div>
              <Button
                sx={{
                  marginLeft: "10px",
                  alignItems: "center",
                  marginTop: "20px",
                  // marginBottom: "7px",
                  marginRight: "10px",
                  justifyContent: "10px",
                  width: "90%",
                }}
                variant="contained"
                data-testid="offer"
                onClick={Next}
              >
                Meer Info
              </Button>
            </div>
          </div>
        </Card>
        <div>
          <Accordion
            className="accordion"
            sx={{ boxShadow: "0px 0px 0px none", marginTop: "-3px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: "#1976D2" }}>
                Review bankenvergelijking
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{def ?? ""}</Typography>
              {Object.keys(disc).map((e, index) => {
                return (
                  <Typography key={index}>
                    {" "}
                    <br />
                    <strong>{e}</strong> <br />
                    {disc[e]}
                  </Typography>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
      <br />
    </Grid>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//mui
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
//styles
import "../Step/Styles.css";
//Actions
import {
  setName,
  setPhone,
  setSurname,
  setSubject,
  setPostCode,
  setHouseNo,
  send,
  setEmail,
} from "../../services/contact.service/contactActions";

//Form builders & validators
import { useFormik } from "formik";
import * as yup from "yup";

export const Contact = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setNameVal] = useState("");
  const [surname, setSurnameVal] = useState("");
  const [phone, setPhoneVal] = useState("");
  const [postcode, setPostcodeV] = useState("");
  const [houseNo, setHouseNoV] = useState("");
  const [subject, setSubjectV] = useState("");
  const [email, setEmailV] = useState("");

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [valid, setValid] = useState(true);
  //form builders and validators
  const validationSchema = yup.object({
    name: yup
      .string("Je voornaam is vereist")
      .matches(/^[aA-zZ\s]+$/, "We hebben een geldig voornaam van je nodig")
      .required("Je voornaam is vereist"),
    surname: yup
      .string("Je achternaam is vereist")
      .matches(/^[aA-zZ\s]+$/, "We hebben een geldig achternaam van je nodig")
      .required("Je achternaam is vereist"),
    phone: yup
      .number()
      .typeError("We hebben een geldig telefoonnummer van je nodig")
      .required("Je telefoonnummer is vereist"),
    postcode: yup
      .string()
      .min(6, "moet ten minste 6 tekens bevatten")
      .max(7, "mag niet meer dan 7 tekens bevatten")
      .test("postcode-test", "voer een geldige postcode in", function (value) {
        if (
          isNaN(value.slice(0, 3)) == true ||
          isNaN(value.slice(-2)) == false
        ) {
          return false;
        } else if (
          isNaN(value.slice(0, 3)) == false &&
          isNaN(value.slice(-2))
        ) {
          return true;
        }
      })
      .typeError("We hebben een geldige postcode van je nodig")
      .required("Je postcode is vereist"),
    houseNo: yup
      .number()
      .typeError("We hebben een geldig huisnummer van je nodig")
      .required("Je huisnummer is vereist"),
    subject: yup
      .string("We hebben een onderwerp van je nodig")
      .required("We hebben een onderwerp van je nodig"),
    email: yup
      .string()
      .email("We hebben een geldig emailadres van je nodig")
      .required("Je e-mailadres is vereist"),
  });

  const formik = useFormik({
    initialValues: {
      name,
      surname,
      phone,
      postcode,
      houseNo,
      subject,
      email,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(setName(name));
      dispatch(setSurname(surname));
      dispatch(setPhone(phone));
      dispatch(setEmail(email));
      dispatch(setSubject(subject));
    },
  });

  //State Management:
  useEffect(() => {
    const data = window.sessionStorage.getItem("name");
    const data2 = window.sessionStorage.getItem("surname");
    const data3 = window.sessionStorage.getItem("phone");
    const data4 = window.sessionStorage.getItem("houseNo");
    const data5 = window.sessionStorage.getItem("postcode");
    const data6 = window.sessionStorage.getItem("subject");
    const data7 = window.sessionStorage.getItem("email");

    //Remember form values on refresh and navigation.

    formik.setFieldValue("name", data && data !== "null" ? data : "");
    formik.setFieldValue("surname", data2 && data2 !== "null" ? data2 : "");
    formik.setFieldValue("phone", data3 && data3 !== "null" ? data3 : "");
    formik.setFieldValue("houseNo", data4 && data4 !== "null" ? data4 : "");
    formik.setFieldValue("postcode", data5 && data5 !== "null" ? data5 : "");
    formik.setFieldValue("subject", data6 && data6 !== "null" ? data6 : "");
    formik.setFieldValue("email", data7 && data7 !== "null" ? data7 : "");

    //setting state slices to input values
    setNameVal(data);
    setSurnameVal(data2);
    setPhoneVal(data3);
    setHouseNoV(data4);
    setPostcodeV(data5);
    setSubjectV(data6);
    setEmailV(data7);
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("name", name);
    window.sessionStorage.setItem("surname", surname);
    window.sessionStorage.setItem("phone", phone);
    window.sessionStorage.setItem("houseNo", houseNo);
    window.sessionStorage.setItem("postcode", postcode);
    window.sessionStorage.setItem("subject", subject);
    window.sessionStorage.setItem("email", email);
  }, [name, surname, phone, houseNo, postcode, email, subject]);

  //To make sure that state is persistant on refresh.
  useEffect(() => {
    dispatch(setName(window.sessionStorage.getItem("name")));
    dispatch(setSurname(window.sessionStorage.getItem("surname")));
    dispatch(setPhone(window.sessionStorage.getItem("phone")));
    dispatch(setHouseNo(window.sessionStorage.getItem("houseNo")));
    dispatch(setPostCode(window.sessionStorage.getItem("postcode")));
    dispatch(setSubject(window.sessionStorage.getItem("subject")));
    dispatch(setEmail(window.sessionStorage.getItem("email")));
  }, [name, surname, phone, postcode, houseNo, subject, email]);

  //event handling
  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  let stuff = useSelector((state) => state.contact);
  const Next = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    send(stuff);
    if (send(stuff)) {
      setMessage(
        "Dank u! We hebben uw e-mail ontvangen. We zullen contact met u opnemen."
      );
      handleClickOpen();
    } else {
      setMessage(
        "Helaas kon je email niet verwerkt worden. Excuses voor het ongemak. Probeer het opnieuw."
      );
      handleClickOpen();
    }
  };
  const handleThisName = (e) => {
    e.preventDefault();
    setNameVal(e.target.value);
  };
  const handleThisSurName = (e) => {
    e.preventDefault();
    setSurnameVal(e.target.value);
  };
  const handleThisEmail = (e) => {
    e.preventDefault();
    setEmailV(e.target.value);
  };
  const handleThisPhone = (e) => {
    e.preventDefault();
    setPhoneVal(e.target.value);
  };
  const handleThisPostCode = (e) => {
    e.preventDefault();
    setPostcodeV(e.target.value);
  };
  const handleThisHouseNo = (e) => {
    e.preventDefault();
    setHouseNoV(e.target.value);
  };
  const handleThisSubject = (e) => {
    e.preventDefault();
    setSubjectV(e.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid container direction="row" marginTop="-10px">
      <div className="back-btn" onClick={onBack}>
        <IconButton>
          <ArrowBackIos />
        </IconButton>
      </div>
      <Grid
        container
        direction="row"
        marginBottom="20px"
        justifyContent="center"
        className="view"
      >
        <Card
          className="formCard"
          sx={{
            justifyContent: "center",
            backgroundColor: "#F7F7F7",
            boxShadow: "none",
          }}
        >
          <div>
            <Typography
              component="div"
              variant="h6"
              sx={{
                marginLeft: "9px",
                fontSize: 20,
                color: "#5cbfc3",
                fontFamily: "Lato, Helvetica Neue Arial, sans-serif",
                fontWeight: 500,
              }}
            >
              Vul je contactgegevens in voor een vrijblijvend gesprek:
            </Typography>
            <Divider />
            <Grid
              container
              direction="row"
              justifyContent="center"
              sx={{ marginTop: "10px" }}
            >
              <FormControl variant="filled" sx={{ width: "320px" }}>
                <TextField
                  sx={{ marginBottom: "10px" }}
                  required
                  variant="filled"
                  id="name"
                  name="name"
                  label="Voornaam"
                  size="small"
                  data-testid="name"
                  value={formik.values.name}
                  onInput={handleThisName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <TextField
                  sx={{ marginBottom: "10px" }}
                  required
                  variant="filled"
                  id="surname"
                  name="surname"
                  size="small"
                  data-testid="surname"
                  value={formik.values.surname}
                  onInput={handleThisSurName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.surname && Boolean(formik.errors.surname)
                  }
                  helperText={formik.touched.surname && formik.errors.surname}
                  label="Achternaam"
                />
                <TextField
                  sx={{ marginBottom: "10px" }}
                  required
                  variant="filled"
                  id="email"
                  name="email"
                  size="small"
                  value={formik.values.email}
                  onInput={handleThisEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  label="E-mailadres"
                />

                <TextField
                  sx={{ marginBottom: "10px" }}
                  required
                  variant="filled"
                  id="postcode"
                  name="postcode"
                  size="small"
                  data-testid="postcode"
                  value={formik.values.postcode}
                  onInput={handleThisPostCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.postcode && Boolean(formik.errors.postcode)
                  }
                  helperText={formik.touched.postcode && formik.errors.postcode}
                  label="Postcode"
                />

                <TextField
                  sx={{ marginBottom: "10px" }}
                  required
                  variant="filled"
                  id="houseNo"
                  name="houseNo"
                  size="small"
                  value={formik.values.houseNo}
                  onInput={handleThisHouseNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.houseNo && Boolean(formik.errors.houseNo)
                  }
                  helperText={formik.touched.houseNo && formik.errors.houseNo}
                  label="Huisnr"
                />

                <TextField
                  required
                  variant="filled"
                  sx={{ marginBottom: "10px" }}
                  id="phone"
                  name="phone"
                  size="small"
                  data-testid="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onInput={handleThisPhone}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  label="Telefoonnummer"
                  placeholder="+33 12347968"
                />

                <TextField
                  required
                  multiline
                  variant="filled"
                  size="large"
                  rows={4}
                  id="subject"
                  name="subject"
                  value={formik.values.subject}
                  onInput={handleThisSubject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.subject && Boolean(formik.errors.subject)
                  }
                  helperText={formik.touched.subject && formik.errors.subject}
                  label="Waarmee kunnen we je verder helpen?"
                />

                <Button
                  sx={{
                    marginTop: "20px",
                    marginLeft: "45px",
                    alignItems: "center",
                    marginBottom: "7px",

                    justifyContent: "10px",
                    width: "70%",
                    marginBottom: "20px",
                  }}
                  variant="contained"
                  onClick={(e) => {
                    Next(e);
                  }}
                  data-testid="nextButton"
                  disabled={!formik.dirty || !formik.isValid}
                >
                  Verzenden
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {message}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </FormControl>
            </Grid>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

import { ContactActionTypes } from "./ContactActionTypes";

const initialState = {
  Name: "",
  Phone: "",
  Surname: "",
  HouseNo: "",
  PostCode: "",
  Subject: "",
  Email: "",
};

export const ContactReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ContactActionTypes.SET_NAME:
      return { ...state, Name: payload };
    case ContactActionTypes.SET_PHONE:
      return { ...state, Phone: payload };
    case ContactActionTypes.SET_SURNAME:
      return { ...state, Surname: payload };
    case ContactActionTypes.SET_HOUSENO:
      return { ...state, HouseNo: payload };
    case ContactActionTypes.SET_POSTCODE:
      return { ...state, PostCode: payload };

    case ContactActionTypes.SET_SUBJECT:
      return { ...state, Subject: payload };

    case ContactActionTypes.SET_EMAIL:
      return { ...state, Email: payload };

    default:
      return state;
  }
};

//react
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//mui
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

//styles
import "./Styles.css";

export const Thanks = () => {
  const Navigate = useNavigate();

  //Selecting useful bits of state:
  const [logo, setLogo] = useState();
  const [product, setProduct] = useState();
  const [jaarlijkse_kosten, setJaarlijkse_kosten] = useState("");
  const [deposit, setDeposit] = useState("");
  //navigation
  const location = useLocation();
  useEffect(() => {
    if (location.state !== "fromStep7") {
      //step2 should only be accessible if i come from step1!
      //otherwise, go back to step1.
      Navigate(-1);
    }
  }, []);
  useEffect(() => {
    const product1 = window.sessionStorage.getItem("product");
    const logo1 = window.sessionStorage.getItem("logo");
    const deposit1 = window.sessionStorage.getItem("deposit");
    const jaarlijkse_kosten1 =
      window.sessionStorage.getItem("jaarlijkse_kosten");
    setProduct(product1);
    setLogo(logo1);
    setJaarlijkse_kosten(jaarlijkse_kosten1);
    setDeposit(deposit1);
  }, []);
  let locState = "fromStep5or8";

  //event handling
  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };

  const Next = (e) => {
    e.preventDefault();
    Navigate(-2, { state: locState }, { replace: true });
  };
  return (
    <Grid
      container
      direction="row"
      marginLeft="-13px"
      // marginTop="60px"
    >
      <div className="back-btn" onClick={onBack}>
        <IconButton>
          <ArrowBackIos />
        </IconButton>
      </div>
      <Grid
        container
        direction="row"
        position="absolute"
        marginBottom="20px"
        // display="flex"
        justifyContent="center"
        className="view"
      >
        <Card
          className="formCard"
          sx={{
            justifyContent: "center",
            backgroundColor: "#F7F7F7",
            boxShadow: "none",
            // minWidth: 400,
            marginLeft: "-30px",
          }}
        >
          <div
            className="picked"
            style={{
              width: "180px",
            }}
          >
            <Card
              sx={{
                border: 2,
                borderColor: "lightblue",
                borderRadius: 1,
                boxShadow: "none",
                height: "auto",
                backgroundColor: "#F7F7F7",
              }}
            >
              <CardMedia
                component="img"
                className="providerLogo"
                sx={{
                  border: 1,
                  borderColor: "darkgray",
                  borderRadius: 1,

                  marginTop: "6px",
                }}
                image={logo}
                alt="providers logo"
                style={{ height: "50px", width: "80px" }}
              />
              <div>
                <Typography
                  component="div"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginLeft: "9px",
                    marginTop: "6px",
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                >
                  {product}
                </Typography>
                <Typography
                  component="div"
                  variant="h6"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  vermogensbeheer
                </Typography>
                <Typography
                  component="div"
                  variant="h6"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  <strong>minimale inleg: </strong>
                 € {deposit}
                </Typography>
                <Typography
                  component="div"
                  variant="h6"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  <strong>Jaarlijkse kosten: </strong>
                  {jaarlijkse_kosten}%
                </Typography>
                <Divider light />
              </div>
            </Card>
          </div>
        </Card>
        <Box sx={{ marginTop: "30px", marginLeft: "30px" }}>
          <Typography
            component="div"
            variant="h6"
            fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
            sx={{
              marginTop: "6px",
              fontSize: 20,
              fontWeight: 600,
              marginLeft: 1,
            }}
          >
            Bedankt voor je aanvraag
          </Typography>
          <Button
            variant="contained"
            onClick={Next}
            sx={{
              marginLeft: "10px",
              alignItems: "center",
              marginRight: "10px",
              marginTop: "20px",
              justifyContent: "10px",
              width: "auto",
            }}
          >
            Vraag meer offertes aan
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

//react
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//styles
import "./Styles.css";
//mui
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Button, Card, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
//reusable component
import { Provider } from "../Provider";
import { getFilteredProducts } from "../../services/Form/FormActions";
import { useDispatch, useSelector } from "react-redux";
import { descriptionText } from "../../services/Card/description";
export const Step6 = () => {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  //navigation
  const location = useLocation();
  const LS = location.state;
  const dispatch=useDispatch()
  //dialog
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //event handling
  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  const contact=(e)=>{
    e.preventDefault();
    Navigate("../contact")
}

  //Load products
  const getData = async () => {
    setLoading(true);
    try {
      let result = await getFilteredProducts("vermogensbeheer");
      console.log(result);
      setData(result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    
    getData();
  }, []);

  return (
    <Grid container direction="row">
      <div className="back-btn" onClick={onBack}>
        <IconButton>
          <ArrowBackIos />
        </IconButton>
      </div>
      <Grid
        container
        direction="column"
        // alignItems="center"
        // justifyContent="center"
        marginTop="60px"
        className="title"
      >
        <Typography
          component="div"
          variant="h5"
          sx={{
            fontWeight: 500,
            fontFamily: "Merriweather  serif",
            fontSize: "2rem",
            lineHeight: "36px",
            textAlign: "center",
            // marginLeft: "20%",
            marginBottom: "20px",
          }}
        >
          Top 3 Beste aanbieders voor vermogensbeheer:
        </Typography>

        <IconButton
          color="primary"
          aria-label="question"
          component="span"
          onClick={handleClickOpen}
        >
          <HelpOutlineIcon />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <strong>Hoe we meten:</strong>
              <br />
              Beoordelingscretaria Beheerkosten: alle onvermijdelijke kosten als
              percentage bij een vermogen van 100.000 euro
              <br />
              Reviewscore: Onze onafhankelijke score die we baseren op gemak van
              klantenservice en gebruikersrecensies.
              <br /> <br />
              <strong>Hoe we matchen:</strong>
              <br />
              Je ingevulde profiel bepaalt welke producttype bij je past. We
              vergelijken aanbieders die vergelijkbare dienstverlening bieden
              binnen jouw gekozen profiel
              <br />
              We selecteren uit een lijst van ruim 50 beleggingsproducten in
              verschillende categoriën in.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {data.sort((a,b)=>(a.jaarlijkse_kosten>b.jaarlijkse_kosten)?1:((b.jaarlijkse_kosten>a.jaarlijkse_kosten)?-1:0)).map((product, index) => {
          let descript=descriptionText.filter((e)=>{
            return product.product===e.product
          })[0]
          console.log(descript);
          return (
            <Provider
              key={index}
              logo={product.product_img}
              name={product.product}
              product_link={product.product_link}
              type={product.type}
              jaarlijkse_kosten={product.jaarlijkse_kosten}
              min_deposit={product.min_deposit}
              description={descript}
              
            />
          );
        })}
        <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        
      >
        <Card
        className="container"
        sx={{
          maxWidth: 520,
          marginTop:"30px",
          marginBottom:"30px",
          alignItems: "center",
          justifyContent: "center",
          display:"flex"
        }}
        >
          <div >
            <Typography>
              <strong>
              Advies
              </strong>
              <br />
              We kunnen je als bankenvergelijking vrijblijvend adviseren in je keuze met beleggen.
            </Typography>
          </div>
          <div >
              <Button
              sx={{
                marginLeft: "90px",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "7px",
                marginRight: "10px",
                justifyContent: "10px",
                height:"60px"
                
              }}
              variant="outlined"
              onClick={contact}
              >
              Contact ons
              </Button>
          </div>
         

        </Card>
      </Grid>
      </Grid>
    </Grid>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Step52 } from "./Step52";
import { Step51 } from "./Step51";

export const Step5Parent = () => {
    const [type, setType] = useState();
    const invest = window.sessionStorage.getItem("invest"); // useSelector((state) => state.formInfo.Invest);
    const products = JSON.parse(window.sessionStorage.getItem("products")); //useSelector((state) => state.formInfo.products);
    const investAmount = window.sessionStorage.getItem("investAmount"); //useSelector((state) => state.formInfo.investAmount);

    const redirect = (invest, products, investAmount) => {
        if (
            ((invest == 1 || invest == 2) &&
                products.no !== true &&
                products.assets !== true) ||
            investAmount == 1 ||
            investAmount == 2
        ) {
            setType(1); //Let's Invest (laten Beleggen)
        } else if (invest == 2 && products.assets === true) {
            setType(2);
        } else setType(2); //asset management (vermogens...??? ah?)
    };
    useEffect(() => {
        redirect(invest, products, investAmount);
    }, []);
    return (
        <>{type ? type == 1 ? <Step51 /> : <Step52 /> : <>user type unknown</>}</>
    );
};

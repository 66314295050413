export const CardActionTypes = {
    //Card Action Types
    SET_LOGO: "SET_LOGO",
    SET_CREDIT: "SET_CREDIT",
    SET_PROPOSED_LOAN: "SET_PROPOSED_LOAN",
    SET_DISPENSING: "SET_DISPENSING",
    SET_DURATION: "SET_DURATION",
    SET_AMOUNTD: "SET_AMOUNTD",
    SET_FINANCE: "SET_FINANCE",
    SET_MANAGE: "SET_MANAGE",
    SET_TOTAL: "SET_TOTAL",
    SET_SELECTEDINFO: "SET_SELECTEDINFO",
    SET_SELECTEDLOGO: "SET_SELECTEDLOGO",
    SET_MINL: "SET_MINL",
    SET_MAXL: "SET_MAXL",
    SET_RATE: "SET_RATE",



};
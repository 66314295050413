export const FormActionTypes = {
    //Form Action Types
    SET_INVEST: "SET_INVEST",
    SET_INVESTAMOUNT: "SET_INVESTAMOUNT",
    SET_PRODUCTS: "SET_PRODUCTS",
    SET_ADVICE: "SET_ADVICE",
    SET_CLIENTS: "SET_CLIENTS",
    SET_EMAIL: "SET_EMAIL",
    SET_NAME: "SET_NAME",
    SET_SURNAME: "SET_SURNAME",
    SET_PHONE: "SET_PHONE",
    SET_POSTCODE: "SET_POSTCODE",
    SET_HOUSENO: "SET_HOUSENO",
    
};  
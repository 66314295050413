//react
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
//mui
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
//actions
import {
  SendfullState,
  setName,
  setPhone,
  setSurname,
  setHouseNo,
  setPostCode,
} from "../../services/Form/FormActions";

//Form builders & validators
import { useFormik } from "formik";
import * as yup from "yup";
//styles
import "./Styles.css";
import { setOffer } from "../../services/Card/CardActions";

export const Step7 = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  //Selecting useful bits of state:
  const offer = useSelector((state) => state.cardReducer.offers);
  console.log(offer[offer.length - 1]);
  const [logo, setLogo] = useState();
  const [product, setProduct] = useState();
  const [jaarlijkse_kosten, setJaarlijkse_kosten] = useState("");
  const [deposit, setDeposit] = useState("");
  const [error, setError] = useState(false);

  // const logo=useSelector((state)=>state.cardReducer.offers[offer.length - 1]?.logo)
  // const product=useSelector((state)=>state.cardReducer.offers[offer.length - 1]?.name)
  //Local state and setters:
  const [name, setNameVal] = useState("");
  const [surname, setSurnameVal] = useState("");
  const [phone, setPhoneVal] = useState("");
  const [postcode, setPostcodeV] = useState("");
  const [houseNo, setHouseNoV] = useState("");

  //navigation
  const location = useLocation();
  // console.log(location.state);
  useEffect(() => {
    if (location.state !== "fromStep5or8") {
      //should only be accessible from previous step
      Navigate(-1);
    }
  }, []);
  let locState = "fromStep7";

  //form builders and validators
  const validationSchema = yup.object({
    name: yup
      .string("Je voornaam is vereist")
      .matches(/^[aA-zZ\s]+$/, "We hebben een geldig voornaam van je nodig")
      .required("Je voornaam is vereist"),
    surname: yup
      .string("Je achternaam is vereist")
      .matches(/^[aA-zZ\s]+$/, "We hebben een geldig achternaam van je nodig")
      .required("Je achternaam is vereist"),
    phone: yup
      .number()
      .typeError("We hebben een geldig telefoonnummer van je nodig")
      .required("Je telefoonnummer is vereist"),
    postcode: yup
      .string()
      .min(6, "moet ten minste 6 tekens bevatten")
      .max(7, "mag niet meer dan 7 tekens bevatten")
      .test("postcode-test", "voer een geldige postcode in", function (value) {
        if (
          isNaN(value.slice(0, 3)) == true ||
          isNaN(value.slice(-2)) == false
        ) {
          return false;
        } else if (
          isNaN(value.slice(0, 3)) == false &&
          isNaN(value.slice(-2))
        ) {
          return true;
        }
      })
      .typeError("We hebben een geldige postcode van je nodig")
      .required("Je postcode is vereist"),
    houseNo: yup
      .number()
      .typeError("We hebben een geldig huisnummer van je nodig")
      .required("Je huisnummer is vereist"),
  });

  const formik = useFormik({
    initialValues: {
      name,
      surname,
      phone,
      postcode,
      houseNo,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(setName(name));
      dispatch(setSurname(surname));
      dispatch(setPhone(phone));
      dispatch(setPostCode(postcode));
      dispatch(setHouseNo(houseNo));
      Navigate("../step/9", { state: locState });
    },
  });

  //State Management:
  useEffect(() => {
    const data = window.sessionStorage.getItem("name");
    const data2 = window.sessionStorage.getItem("surname");
    const data3 = window.sessionStorage.getItem("phone");
    const data4 = window.sessionStorage.getItem("houseNo");
    const data5 = window.sessionStorage.getItem("postcode");
    const product1 = window.sessionStorage.getItem("product");
    const logo1 = window.sessionStorage.getItem("logo");
    const deposit1 = window.sessionStorage.getItem("deposit");
    const jaarlijkse_kosten1 =
      window.sessionStorage.getItem("jaarlijkse_kosten");

    //Remember form values on refresh and navigation.

    formik.setFieldValue("name", data && data !== "null" ? data : "");
    formik.setFieldValue("surname", data2 && data2 !== "null" ? data2 : "");
    formik.setFieldValue("phone", data3 && data3 !== "null" ? data3 : "");
    formik.setFieldValue("houseNo", data4 && data4 !== "null" ? data4 : "");
    formik.setFieldValue("postcode", data5 && data5 !== "null" ? data5 : "");

    //setting state slices to input values
    setNameVal(data);
    setSurnameVal(data2);
    setPhoneVal(data3);
    setHouseNoV(data4);
    setPostcodeV(data5);
    setProduct(product1);
    setLogo(logo1);
    setJaarlijkse_kosten(jaarlijkse_kosten1);
    setDeposit(deposit1);
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("name", name);
    window.sessionStorage.setItem("surname", surname);
    window.sessionStorage.setItem("phone", phone);
    window.sessionStorage.setItem("houseNo", houseNo);
    window.sessionStorage.setItem("postcode", postcode);
  }, [name, surname, phone, houseNo, postcode]);

  // console.log("hello!", name);
  // console.log("getItem", window.sessionStorage.getItem("name"));

  //To make sure that state is persistant on refresh.
  useEffect(() => {
    dispatch(setName(window.sessionStorage.getItem("name")));
    dispatch(setSurname(window.sessionStorage.getItem("surname")));
    dispatch(setPhone(window.sessionStorage.getItem("phone")));
    dispatch(setHouseNo(window.sessionStorage.getItem("houseNo")));
    dispatch(setPostCode(window.sessionStorage.getItem("postcode")));
  }, [name, surname, phone, postcode, houseNo]);

  useEffect(() => {
    dispatch(setOffer(logo, product));
  }, [product, logo]);

  //event handling
  const handleClickOpen = () => {
    formik.handleSubmit();
    sendAllData();
  };
  const handleThisName = (e) => {
    e.preventDefault();
    setNameVal(e.target.value);
  };
  const handleThisSurName = (e) => {
    e.preventDefault();
    setSurnameVal(e.target.value);
  };
  const handleThisPhone = (e) => {
    e.preventDefault();
    setPhoneVal(e.target.value);
  };
  const handleThisPostCode = (e) => {
    e.preventDefault();
    setPostcodeV(e.target.value);
  };
  const handleThisHouseNo = (e) => {
    e.preventDefault();
    setHouseNoV(e.target.value);
  };
  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  const handleChecked = (e) => {
    e.preventDefault();
    setError(e.target.checked);
  };
  //sending state data to server
  const fullState = useSelector((state) => state);
  console.log("my full state is : ", fullState);
  const sendAllData = () => {
    SendfullState(fullState);
  };

  return (
    <Grid
      container
      direction="row"
      // marginTop="60px"
    >
      <div className="back-btn" onClick={onBack}>
        <IconButton>
          <ArrowBackIos />
        </IconButton>
      </div>
      <Grid
        container
        direction="row"
        position="absolute"
        marginBottom="20px"
        justifyContent="center"
        className="view"
      >
        <Card
          className="formCard"
          sx={{
            justifyContent: "center",
            backgroundColor: "#F7F7F7",
            boxShadow: "none",
            // minWidth: 400,
            marginLeft: "15px",
          }}
        >
          <div
            className="picked"
            style={{
              width: "190px",
            }}
          >
            <Card
              sx={{
                border: 2,
                borderColor: "lightblue",
                borderRadius: 1,
                boxShadow: "none",
                height: "auto",
                backgroundColor: "#F7F7F7",
              }}
            >
              <CardMedia
                component="img"
                className="providerLogo"
                sx={{
                  border: 1,
                  borderColor: "darkgray",
                  borderRadius: 1,
                  marginTop: "6px",
                }}
                image={logo}
                alt="provider's logo"
                style={{ height: "50px", width: "80px" }}
              />
              <div>
                <Typography
                  component="div"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginLeft: "9px",
                    marginTop: "6px",
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                >
                  {product}
                </Typography>
                <Typography
                  component="div"
                  variant="h6"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  vermogensbeheer
                </Typography>
                <Typography
                  component="div"
                  variant="h6"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  <strong>minimale inleg: </strong>€ {deposit}
                </Typography>
                <Typography
                  component="div"
                  variant="h6"
                  fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                  sx={{
                    marginLeft: "9px",
                    fontSize: 13,
                  }}
                >
                  <strong>Jaarlijkse kosten: </strong>
                  {jaarlijkse_kosten}%
                </Typography>
                <Divider light />
              </div>
            </Card>
          </div>
          <div style={{ width: "300px" }}>
            <Typography
              component="div"
              variant="h6"
              sx={{
                marginLeft: "9px",
                fontSize: 20,
                color: "#5cbfc3",
                fontFamily: "Lato, Helvetica Neue Arial, sans-serif",
                fontWeight: 500,
              }}
            >
              Vul je contactgegevens in voor een vrijblijvend gesprek:
            </Typography>
            <Divider />
            <Box sx={{ marginTop: "20px" }}>
              <FormControl
                variant="filled"
                className="centerMyForm"
                margin="dense"
              >
                <TextField
                  sx={{ marginBottom: "10px" }}
                  required
                  variant="filled"
                  id="name"
                  label="Voornaam"
                  size="small"
                  data-testid="name"
                  value={formik.values.name}
                  onInput={handleThisName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <TextField
                  sx={{ marginBottom: "10px" }}
                  required
                  variant="filled"
                  id="surname"
                  size="small"
                  data-testid="surname"
                  value={formik.values.surname}
                  onInput={handleThisSurName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.surname && Boolean(formik.errors.surname)
                  }
                  helperText={formik.touched.surname && formik.errors.surname}
                  label="Achternaam"
                />

                <TextField
                  sx={{ marginBottom: "10px" }}
                  required
                  variant="filled"
                  id="postcode"
                  name="postcode"
                  size="small"
                  data-testid="postcode"
                  value={formik.values.postcode}
                  onInput={handleThisPostCode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.postcode && Boolean(formik.errors.postcode)
                  }
                  helperText={formik.touched.postcode && formik.errors.postcode}
                  label="Postcode"
                />

                <TextField
                  sx={{ marginBottom: "10px" }}
                  required
                  variant="filled"
                  id="house"
                  name="houseNo"
                  size="small"
                  value={formik.values.houseNo}
                  onInput={handleThisHouseNo}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.houseNo && Boolean(formik.errors.houseNo)
                  }
                  helperText={formik.touched.houseNo && formik.errors.houseNo}
                  label="Huisnr"
                />

                <TextField
                  required
                  variant="filled"
                  id="phone"
                  size="small"
                  data-testid="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onInput={handleThisPhone}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  label="Telefoonnummer"
                  placeholder="+33 12347968"
                />
                <br />
                <FormControlLabel
                  style={{
                    fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                  }}
                  label={
                    <Typography
                      className="checkbox"
                      fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                    >
                      Ja, ik ga akkoord met het delen van mijn gegevens, zodat
                      de gekozen vermogensbeheer mij via email of telefoon mag
                      benaderen.
                    </Typography>
                  }
                  control={
                    <Checkbox onChange={handleChecked} type="checkbox" />
                  }
                  id="checkb1"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.checkb1 && Boolean(formik.errors.checkb1)
                  }
                  helperText={formik.touched.checkb1 && formik.errors.checkb1}
                />

                <br />

                <Button
                  sx={{
                    marginLeft: "10px",
                    alignItems: "center",
                    marginBottom: "7px",
                    marginRight: "10px",
                    justifyContent: "10px",
                    width: "90%",
                  }}
                  variant="contained"
                  onClick={() => {
                    handleClickOpen();
                  }}
                  data-testid="nextButton"

                >
                  Vrijblijvende offerte
                </Button>
              </FormControl>
            </Box>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./Step/Styles.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { descriptionText } from "../services/Card/description";
import { setOffer } from "../services/Card/CardActions";
export const Provider = (props) => {
  const {
    logo,
    name,
    product_link,
    type,
    jaarlijkse_kosten,
    min_deposit,
    description: { product, def, ...disc },
    ...rest
  } = props;

  //dialog
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const Navigate = useNavigate();
  let locState = "fromStep5or8";
  const dispatch = useDispatch();

  const Next = (e) => {
    // dispatch(setOffer(logo, name));
    window.sessionStorage.setItem("logo", logo);
    window.sessionStorage.setItem("product", name);
    window.sessionStorage.setItem("deposit", min_deposit);
    window.sessionStorage.setItem("jaarlijkse_kosten", jaarlijkse_kosten);

    Navigate("../step/7", { state: locState });
  };
  // console.log(location.state);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        direction="column"
        sx={{
          // width: "590px",
          maxWidth: 700,
          minHeight: 200,
          alignItems: "center",
          justifyContent: "center",
        }}
        className="container"
      >
        <Card
          className="container"
          sx={{
            // minWidth: 500,
            maxWidth: 700,
            minHeight: 200,
            // display:'flex',
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "135px" }}>
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={logo}
              alt="provider's logo"
              style={{
                width: "100px",
                marginLeft: "10px",
              }}
            />
          </div>
          <div style={{ width: "200px", marginLeft: "9px" }}>
            <div style={{ height: "50px" }}>
              <Typography
                component="div"
                variant="subtitle"
                fontFamily=" Lato, Helvetica Neue Arial, sans-serif"
                sx={{
                  fontSize: 20,
                }}
              >
                <strong>{name}</strong> <br />
              </Typography>
            </div>
            <div>
              <Typography
                component="div"
                variant="subtitle"
                color="#2E353C"
                sx={{ fontSize: 13 }}
              >
                <strong
                  style={{
                    fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                  }}
                >
                  Type: &nbsp;
                </strong>
                {type}
              </Typography>
            </div>
            <div>
              <Typography
                component="div"
                variant="subtitle"
                color="#2E353C"
                sx={{ fontSize: 13 }}
              >
                <strong
                  style={{
                    fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                  }}
                >
                  Jaarlijkse kosten: &nbsp;
                </strong>
                {jaarlijkse_kosten}%
              </Typography>
            </div>
            <div>
              <Typography
                component="div"
                variant="subtitle"
                color="#2E353C"
                sx={{ fontSize: 13 }}
              >
                <strong
                  style={{
                    fontFamily: " Lato, Helvetica Neue Arial, sans-serif",
                  }}
                >
                  Minimale inleg: &nbsp;
                </strong>{" "}
                € {min_deposit}
              </Typography>
            </div>
          </div>
          <div style={{ width: "210px", margin: "auto" }}>
            <div>
              <Button
                sx={{
                  marginLeft: "10px",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "7px",
                  marginRight: "10px",
                  justifyContent: "10px",
                  width: "90%",
                }}
                variant="contained"
                data-testid="offer"
                onClick={handleClickOpen}
              >
                Bel ons
              </Button>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Bel ons"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  085 06 08 805
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <div>
              <Button
                sx={{
                  marginLeft: "10px",
                  alignItems: "center",

                  // marginBottom: "7px",
                  marginRight: "10px",
                  justifyContent: "10px",
                  width: "90%",
                }}
                variant="contained"
                data-testid="offer"
                onClick={Next}
              >
                Oriëntatiegesprek
              </Button>
            </div>
          </div>
        </Card>
        <div>
          <Accordion
            className="accordion"
            sx={{ boxShadow: "0px 0px 0px none", marginTop: "-3px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ color: "#1976D2" }}>
                Review bankenvergelijking
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{def ?? ""}</Typography>
              {Object.keys(disc).map((e, index) => {
                return (
                  <Typography key={index}>
                    {" "}
                    <br />
                    <strong>{e}</strong> <br />
                    {disc[e]}
                  </Typography>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
    </Grid>
  );
};

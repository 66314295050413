import axios from "axios";
import { ContactActionTypes } from "./ContactActionTypes";
const baseUrl = process.env.REACT_APP_SERVER_URL;

export const setName = (name) => {
    return {
      type: ContactActionTypes.SET_NAME,
      payload: name,
    };
  };
  
  export const setSurname = (surname) => {
    return {
      type: ContactActionTypes.SET_SURNAME,
      payload: surname,
    };
  };
  
  export const setPhone = (phone) => {
    return {
      type: ContactActionTypes.SET_PHONE,
      payload: phone,
    };
  };
  export const setPostCode = (postCode) => {
    return {
      type: ContactActionTypes.SET_POSTCODE,
      payload: postCode,
    };
  };
  export const setHouseNo = (houseNo) => {
    return {
      type: ContactActionTypes.SET_HOUSENO,
      payload: houseNo,
    };
  };
  export const setSubject=(subject)=>{
      return {
          type: ContactActionTypes.SET_SUBJECT,
          payload: subject
      }
  };
  export const setEmail=(email)=>{
    return {
        type: ContactActionTypes.SET_EMAIL,
        payload: email
    }
};

  export const send= async (stuff)=>{
    try {
        let res=await axios.post(baseUrl+"/users/contact", stuff);
        console.log(res.data);

    } catch (error) {
        console.log("couldn't send ur stuff", error);
    }
  }
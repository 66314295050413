import { combineReducers } from "redux";
import { CardReducer } from "./Card/CardReducer";
import { ContactReducer } from "./contact.service/ContactReducer";
import { InfoReducer } from "./Form/InfoReducer";

const reducers=combineReducers({
    formInfo: InfoReducer,
    cardReducer: CardReducer,
    contact: ContactReducer,
})

export default reducers;
export const descriptionText = [
  { 
    //product name:
    product: "Brandnewday Modelbeleggen",
    //first parag
    def: "Wil je goed gespreid beleggen in zo’n 20.000 aandelen en obligaties zonder zelf te hoeven handelen op de beurs? Dan kun je kiezen voor een modelportefeuille van Brand New Day. \n Je kunt zoveel en zo vaak inleggen als je wilt. Als je het geld tussentijds nodig hebt, kun je er altijd bij. Ook stoppen kan op ieder moment.",
    //Waarin kun je beleggen?
    "Waarin kun je beleggen?": "Er zijn vijf verschillende modelportefeuilles: zeer defensief, defensief, neutraal, offensief en zeer offensief. Iedere portefeuille bestaat uit beleggingsfondsen van Brand New Day in een bepaalde verhouding. Hoe meer aandelenfondsen, hoe risicovoller de portefeuille. \n Als de verhouding van aandelen en obligaties na verloop van tijd verandert, bijvoorbeeld omdat de waarde van aandelen sneller stijgt, dan wordt de verhouding automatisch aangepast. Dat heet herbalanceren. Zo beleg je nooit met meer risico dan de bedoeling was.",
    //Hoe werkt het?
    "Hoe werkt het?": "Je opent een beleggingsrekening bij Brand New Day Bank. Dat is een rekening met een eigen IBAN. Hiermee kun je naar keuze vrij beleggen of modelbeleggen. Bij modelbeleggen hoef je alleen te kiezen voor een van de vijf portefeuilles. Geld dat je overmaakt via iDeal of een bankoverschrijving wordt automatisch belegd in de modelportefeuille.",
    //Wat kost het?
    "Wat kost het?": "Brand New Day rekent 0,34 procent servicekosten. Verder betaal je alleen 0,15 tot 0,17 procent fondskosten, die zijn verwerkt in de koers van de fondsen. Aan- en verkoopkosten zijn er niet en ook switchen tussen portefeuilles is gratis.",
  },
  {
    product: "Centraal Beheer Mixfondsen",
    def: "Met Centraal Beheer Mixfondsen kun je makkelijk beleggen in obligaties en aandelen zonder verstand te hebben van de beurs. Iedereen kan het, want er geldt geen minimumbedrag. \n Je kunt ook wekelijks of maandelijks inleggen of je geld automatisch laten verdelen over verschillende mixfondsen.",
    "Waarin kun je beleggen?": "Je kiest zelf het risico dat bij je past: Voorzichtig, Gemiddeld, Ambitieus of Zeer Ambitieus. Bij elk risico hoort een mixfonds met een combinatie van onder andere aandelen, obligaties en grondstoffen. Het voorzichtige fonds is het meest geschikt als je een beleggingshorizon hebt van 2 tot 5 jaar. Het zeer ambitieuze fonds komt alleen in aanmerking als je het geld minimaal 15 jaar niet nodig hebt. \n De fondsen worden beheerd door Achmea Investment Management en zijn maatschappelijk verantwoord. Je belegt niet in omstreden wapens, omstreden landen en tabaksproducenten bijvoorbeeld.",
    "Hoe werkt het?": "Geld inleggen en opnemen doe je gemakkelijk online. Eerst open je online een beleggingsrekening bij Centraal Beheer. Vervolgens leg je geld in via Mijn Centraal Beheer of de app. \n Je kunt altijd bij je geld. Centraal Beheer rekent daarvoor geen kosten. Ook wisselen tussen de verschillende fondsen is gratis.",
    "Wat kost het?": "De aankoopkosten bedragen 0,30%. Verder betaal je 0,60% fondskosten over je belegde vermogen. Aankoopkosten gaan van je inleg af. Fondskosten zijn verwerkt in de koers en worden dus niet apart in rekening gebracht.",
  },
  {
    product: "ASN Doelbeleggen",
    def: "Beleggen met een goed gevoel, maar ook met een vooropgesteld doel. Dat kan met ASN Doelbeleggen. De online Doelplanner helpt je om je doel, inleg en fonds te kiezen. \n Je kunt in de Doelplanner eerst een inleg invullen en kijken welk doel daarbij past, of eerst een doel bepalen en zien welke inleg daarvoor nodig is.",
    "Waarin kun je beleggen?": "Er is keuze uit vijf mixfondsen, die variëren van zeer defensief tot zeer offensief. Hoe offensiever het fonds, des te groter de kans op een hoog rendement, maar ook op een groot verschil tussen vette en magere jaren. \n Wat alle fondsen gemeen hebben, is de verantwoorde keuze van aandelen en obligaties. Deze zijn uitsluitend van bedrijven en organisaties die met respect omgaan met de wereld en de samenleving.",
    "Hoe werkt het?": "Je opent online een rekening voor ASN Doelbeleggen. Daarna kies je een mixfonds en het percentage dat je wilt beleggen. Vervolgens stort je geld op de rekening om aandelen van het gewenste mixfonds te kunnen kopen. \n Het is de bedoeling dat je regelmatig checkt of je vermogen groeit zoals je dat had verwacht. Je kunt je ook laten waarschuwen als de resultaten tegenvallen.",
    "Wat kost het?": "ASN Doelbeleggen kost 0,3 procent aan servicekosten. \n De fondskosten (maximaal 0,90 procent) en transactiekosten voor aan- en verkopen binnen het fonds (0,05 - 0,29 procent) zijn verwerkt in de koers. \n Je bent volledig vrij om je inleg te verhogen, te verlagen of terug te brengen tot nul.",
  },
  {
    product: "Doelbeleggen",
    def: "Met een beleggingsdoel voor ogen is het makkelijker om aan een strategie vast te houden, ook als het op de beurs even tegenzit. Ups en downs horen bij het beursklimaat en veranderen op de lange termijn meestal niets aan de haalbaarheid van je doel. \n Daarom kies je bij Doelbeleggen.nl eerst je doel, zoals een grote aankoop, aanvullende zorg of een vakantiehuisje. Daarna kies je je inleg, de einddatum en het risico dat je wilt lopen. Hiermee bepaalt Doelbeleggen de juiste beleggingsstrategie. \n Na het openen van een persoonlijk account kun je beginnen met beleggen. Daarbij neemt Doelbeleggen alle beleggingsbeslissingen. Alleen als de haalbaarheid van je doel in gevaar komt, krijg je een alert dat je actie moet ondernemen. De inleg iets verhogen bijvoorbeeld. Of genoegen nemen met een lager resultaat.",
    "Waarin kun je beleggen?": "Doelbeleggen.nl belegt in uiteenlopende ETF’s, oftewel indextrackers. Dit zijn mandjes met aandelen, obligaties en vastgoedbeleggingen, waarmee je geld wereldwijd gespreid is belegd. De kosten van indextrackers zijn relatief laag, omdat ze een vastgestelde lijst volgen: de index",
    "Wat kost het?": "Voor het beheer van je vermogen brengt Doelbeleggen 0,75 procent kosten per jaar in rekening. Voor het aankopen of verkopen van fondsen betaal je 0,10 procent. Ten slotte zijn ook in de koers van de fondsen kosten verwerkt, gemiddeld zo’n 0,21 procent per jaar.",
  },
  {
    product: "ABN Amro Vermogensbeheer",
    def: "Wil je geen omkijken hebben naar je beleggingen en heb je minstens 50.000 euro beschikbaar? Dan kun je terecht bij de vermogensbeheerders van ABN Amro. \n Beleggingsexperts van de bank beleggen je geld voor de lange termijn op de manier die past bij het risico dat je wilt nemen en je doel. Je kiest tussen beleggen in economische groei, beleggen als bron van inkomen en duurzaam beleggen. \n Alle afspraken leg je vast in een mandaat. Daarna doet de bank de rest. Als je wilt, kun je de ontwikkeling van je portefeuille volgen via internetbankieren.\n Geld opnemen kan alleen in overleg met je adviseur. Jaarlijks heb je een persoonlijk gesprek, waarin je aanpassingen kunt bespreken.",
    "Wat kost het?": "De beheerkosten bedragen tussen de 0,97 en 1,39 procent, afhankelijk van het mandaat en het risicoprofiel.\nVerder betaal je alleen lopende kosten voor beleggingsfondsen. Deze zijn verwerkt in de koers van de fondsen waarin je belegt.",
  },
  {
    product: "BeSmart Vermogensbeheer",
    def: "Actief beleggen op de beurs levert geen hoger rendement op dan simpelweg een index volgen. Dat is de overtuiging van BeSmart. Deze vermogensbeheerder belegt daarom alleen in ETF’s: mandjes met aandelen, obligaties en grondstoffen. \nBeleggen bij BeSmart Vermogensbeheer kan alleen als je minimaal 100.000 euro kunt vrijmaken. Ook moet je aangeven hoelang je de tijd hebt om je vermogen te laten groeien en wat je er uiteindelijk mee wilt doen. \nDaarnaast moet je vragen beantwoorden over je gewenste rendement én je reactie op koersverlies. Indexbeleggen beweegt namelijk mee met beursschommelingen en daar moet je wel tegen kunnen. Om het risico te beperken, kun je kiezen voor een lager gewenst rendement.",
    "Waarin kun je beleggen?": "Je belegt uitsluitend in duurzame ETF’s, oftewel indexfondsen. BeSmart kiest zelf fondsen uit die indexen volgen met aandelen, obligaties en grondstoffen.",
    "Wat kost het?": "Beleggen in ETF’s is relatief goedkoop, omdat de fondsen zelf lage beheerkosten hebben, gemiddeld zo’n 0,27 procent per jaar. \nBeSmart berekent voor vermogens tot een half miljoen jaarlijks 1,45 procent beheerkosten (incl. btw). \nDaarnaast betaal je 0,275 procent kosten aan BinckBank, de bank waar de beleggingsrekening loopt. \nDe totale beheerkosten komen daarmee op 1,73 procent.",
  },
];

//React, Redux Imports
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import './Styles.css';
//MUI Imports
import Button from '@mui/material/Button';
import { MenuItem, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//Formik & Yup
import { useFormik } from 'formik';
import * as yup from 'yup';
import { setInvest } from '../../services/Form/FormActions';



export const Step1 = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [invest, setInvestValue] = useState("");
    const [open, setOpen] = React.useState(false);

    //Navigation
    let locState = "fromStep1"


    //Form Builder and Validations:   
    const validationSchema = yup.object({
        invest: yup
            .string()
            .required('Selecteer uw gewenste dienst')
    })

    const formik = useFormik({
        initialValues: {
            invest:"",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            
            //pass a state with the new path. to know where i come from.
            Navigate("../step/2", { state: locState })

        },
       
    })
    //state Management
    //To make sure that state is persistant on refresh.
    useEffect(() => {
        const data = window.sessionStorage.getItem("invest");
    
        formik.setFieldValue("invest", data && data !== "null" ? data : "")
        dispatch(setInvest(data&&data !== "null" ? data : ""))

    }, []); 
    //OnChange:
    useEffect(() => {
        if(invest!==""){
            window.sessionStorage.setItem("invest", invest)
            dispatch(setInvest(invest))
        }
        
    }, [invest]);

   

    //handling events
    const Next = (e) => {
        e.preventDefault()
        formik.handleSubmit()
    }

    const handleThis = (e) => {
        e.preventDefault()
        setInvestValue(e.target.value)
        console.log(e.target.value);
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container
            direction="column"
            alignItems="center"
            // justifyContent="center"
            marginTop="60px"
            width="100%">
            <Box>
                <Card sx={{
                    minHeight: '250px',
                    minWidth: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F7F7F7',
                    boxShadow: 'none',
                }} >
                    <React.Fragment>
                        <CardContent>

                            <FormControl className='centerMyForm' >

                                <Typography
                                    component="div"
                                    variant='h3'
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: "Merriweather  serif",
                                        fontSize: '2.1rem',
                                        lineHeight: '36px',
                                        textAlign: 'center'
                                    }}
                                >
                                    Welke vorm van beleggen spreekt je aan?
                                </Typography>

                                <IconButton color="primary" aria-label="question" component="span"
                                    onClick={handleClickOpen}>
                                    <HelpOutlineIcon />
                                </IconButton>
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"waarom deze vraag:"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Je wilt geld door iemand anders laten beleggen, maar
                                            hoeveel wil je nog zelf doen.
                                            <br />
                                            Sommige producten zijn vooral geschikt als jezelf goed
                                            keuzes kunt en wilt maken. Je blijft dan wel
                                            verantwoordelijk voor de gemaakte keuzes.
                                            <br />
                                            Bij volledig vermogensbeheer geef je de controle bijna
                                            volledig uit handen. Je dan wel vooraf afspraken maken.
                                            Ook hier kun je hulp bij krijgen.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>

                                        <Button onClick={handleClose} autoFocus>
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <br />
                                <FormControl variant='filled' className='centerMyForm' >
                                    <InputLabel id="droplabel" >Gewenste dienstverlening</InputLabel>
                                    <Select
                                        labelId="droplabel"
                                        id='invest'
                                        name='invest'
                                        label="invest"
                                        sx={{ width: "320px" }}
                                        // data-testid="invest"
                                        value={formik.values.invest}
                                        onChange={(e)=>{
                                            formik.handleChange(e)
                                            handleThis(e)
                                        }}
                                        error={formik.touched.invest && Boolean(formik.errors.invest)}
                                    >
                                        <MenuItem sx={{ width: "320px", whiteSpace:"normal" }} value={1} >Ik wil volledig zelf beleggen in individuele aandelen en fondsen.</MenuItem>
                                        <MenuItem sx={{ width: "320px", whiteSpace:"normal" }} value={2}>Ik wil mijn geld door iemand laten beleggen, maar de beleggingsmix blijft mijn keuze.</MenuItem>
                                        <MenuItem sx={{ width: "320px", whiteSpace:"normal" }} value={3}>Ik wil beleggen volledig uitbesteden aan een vermogensbeheerder,
                                            waarmee ik vooraf afspraken maak.</MenuItem>
                                    </Select>
                                    <br />
                                    

                                    <div className='buttonbox'>
                                        <Button variant="contained"
                                            type='submit'
                                            size='large'
                                            className='button'
                                            data-testid="nextButton"

                                            onClick={
                                                Next
                                            }
                                        >
                                            Verder</Button>
                                    </div>
                                </FormControl>
                            </FormControl>
                        </CardContent>
                    </React.Fragment>
                </Card>
            </Box>
        </Grid>
    );
}


import { applyMiddleware, createStore, compose } from '@reduxjs/toolkit'
import reducers from '../services/Reducer'
import thunk from "redux-thunk";
const middleware = [thunk];
const store = createStore(
    reducers,
    {},
    compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
            : f => f

    )

)

export default store;

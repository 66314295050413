//React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
//MUI
import { MenuItem, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//Form builders & validators
import { useFormik } from 'formik';
import * as yup from 'yup';
//Actions
import { setInvestAmount } from '../../services/Form/FormActions';
//Styles
import './Styles.css';

export const Step2 = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [investAmount, setInvestAmountV] = useState("");
    const [open, setOpen] = useState(false);




    //navigation
    const location = useLocation();
    // console.log(location.state);
    useEffect(() => {
        if (location.state !== 'fromStep1') {
            //step2 should only be accessible if i come from step1!
            //otherwise, go back to step1.
            Navigate(-1)
        }
    }, []);

    let locState = "fromStep2"

    //form building and validators
    const validationSchema = yup.object().shape({
        investAmount: yup
            .string()
            .required('Kies uw Inleg')
    })
    const formik = useFormik({
        initialValues: {
            investAmount: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            Navigate("../step/3", { state: locState })
        },
    })
    //State Management:
    //To make sure that state is persistant on refresh.
    useEffect(() => {
        const data = window.sessionStorage.getItem("investAmount");

        formik.setFieldValue("investAmount", data && data !== "null" ? data : "")
        dispatch(setInvestAmount(data && data !== "null" ? data : ""))

    }, []);
    //OnChange:
    useEffect(() => {
        if (investAmount !== "") {
            window.sessionStorage.setItem("investAmount", investAmount)
            dispatch(setInvestAmount(investAmount))
        }

    }, [investAmount]);
    //handling events
    const Next = (e) => {
        formik.handleSubmit()

    }

    const handleThis = (e) => {
        e.preventDefault()
        setInvestAmountV(e.target.value)
    }

    const onBack = (e) => {
        e.preventDefault()
        Navigate(-1)
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <Grid container
            direction="column"
            width="100%"
            marginTop="60px"
        >

            <Box >
                <div className='back-btn' onClick={onBack}>
                    <IconButton  ><ArrowBackIos /></IconButton>
                </div>
                <Card className='bod' sx={{ minHeight: '250px', minWidth: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: 'none', backgroundColor: '#F7F7F7' }} >
                    <React.Fragment>

                        <CardContent>

                            <FormControl className='centerMyForm'>
                                <Typography
                                    component="div"
                                    variant='h3'
                                    sx={{
                                        fontWeight: 500,
                                        fontFamily: "Merriweather  serif",
                                        fontSize: '2.1rem',
                                        lineHeight: '36px',
                                        textAlign: 'center',
                                        marginBottom: "25px"
                                    }}
                                >
                                    Hoeveel heb je eventueel beschikbaar om te beleggen?
                                </Typography>
                                <IconButton color="primary" aria-label="question" component="span"
                                    onClick={handleClickOpen}>
                                    <HelpOutlineIcon />
                                </IconButton>
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"waarom deze vraag:"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Vermogensbeheerder hanteren een minimale inleg. Dit
                                            verschilt sterk per beheerder. <br />
                                            Sommige beheerders zijn alleen toegankelijk met een belegd vermogen van 250.000 euro.
                                            <br />
                                            Bij een hoog vermogen kan een uitgebreidere dienstverlening zinvol zijn.
                                            Niet iedere vermogensbeheerder kan dit aanbieden.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>

                                        <Button onClick={handleClose} autoFocus>
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <br />
                                <FormControl variant='filled' className='centerMyForm' >
                                    <InputLabel id="droplabel" >Inleg</InputLabel>
                                    <Select
                                        labelId="droplabel"
                                        id='investAmount'
                                        name='investAmount'
                                        label="investAmount"
                                        sx={{ width: "320px" }}
                                        // data-testid="invest"

                                        value={formik.values.investAmount}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            handleThis(e)
                                        }}
                                        error={formik.touched.investAmount && Boolean(formik.errors.investAmount)}


                                    >

                                        <MenuItem value={1} >0 - 50.000                         </MenuItem>
                                        <MenuItem value={2}>50.000 - 100.000                    </MenuItem>
                                        <MenuItem value={3}>100.000 - 250.000                     </MenuItem>
                                        <MenuItem value={4}>250.000 - 500.000                    </MenuItem>
                                        <MenuItem value={5}>500.000 of meer                     </MenuItem>
                                    </Select>
                                    <br />
                                    <div className='buttonbox'>
                                        <Button variant="contained"
                                            type='submit'
                                            size="large"
                                            className='button'
                                            data-testid="nextButton"
                                            // disabled={disableButton}
                                            //disabled={!document.getElementById('company').value}
                                            onClick={Next}
                                        >Verder</Button>
                                    </div>
                                </FormControl>
                            </FormControl>
                        </CardContent>
                    </React.Fragment>
                </Card>
            </Box>
        </Grid>
    );

}
//react
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//styles
import "./Styles.css";
//mui
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
//reusable component
import { getFilteredProducts } from "../../services/Form/FormActions";
import { useSelector } from "react-redux";
import { InvestProvider } from "../InvestProvider";
import { descriptionText } from "../../services/Card/description";


export const Step51 = () => {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [description, setDescription] = useState([]);

  //navigation
  const location = useLocation();
  let locState = "fromStep5or8";
  //event handling
  const onBack = (e) => {
    e.preventDefault();
    Navigate(-1);
  };
  //dialog
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //Load products
  const getData = async () => {
    setLoading(true);
    try {
      let result = await getFilteredProducts("laten beleggen");
      console.log(result);
      setData(result);
      setLoading(false);
      setDescription(descriptionText)
      console.log(description);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Grid container direction="row">
      <div className="back-btn" onClick={onBack}>
        <IconButton>
          <ArrowBackIos />
        </IconButton>
      </div>
      <Grid container 
      direction="column" 
      marginTop="60px" 
      // justifyContent="center"
      // alignItems="center"
     className="title"
      >
        <Typography
          component="div"
          variant="h5"
          
          sx={{
            fontWeight: 500,
            fontFamily: "Merriweather  serif",
            fontSize: "2rem",
            lineHeight: "36px",
            textAlign: "center",
            
            // marginLeft: "10%",
            marginBottom: "20px",
          }}
        >
          Top 3 Beste aanbieders voor laten beleggen:
        </Typography>
        <IconButton
          color="primary"
          aria-label="question"
          component="span"
          onClick={handleClickOpen}
        >
          <HelpOutlineIcon />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <strong>Hoe we meten:</strong>
              <br />
              Beoordelingscretaria Beheerkosten: alle onvermijdelijke kosten als
              percentage bij een vermogen van 100.000 euro
              <br />
              <strong>Reviewscore:</strong> <br />  Onze onafhankelijke score die we baseren op gemak van
              klantenservice en gebruikersrecensies.
              <br /> 
              <strong>Hoe we matchen:</strong>
              <br />
              Je ingevulde profiel bepaalt welke producttype bij je past. We
              vergelijken aanbieders die vergelijkbare dienstverlening bieden
              binnen jouw gekozen profiel
              <br />
              We selecteren uit een lijst van ruim 50 beleggingsproducten in
              verschillende categoriën in.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {data.sort((a,b)=>(a.jaarlijkse_kosten>b.jaarlijkse_kosten)?1:((b.jaarlijkse_kosten>a.jaarlijkse_kosten)?-1:0))
        .map((product, index) => {
          let descript=descriptionText.filter((e)=>{
            return product.product===e.product
          })[0]
          console.log(descript);
          return (
            <InvestProvider
              key={index}
              logo={product.product_img}
              name={product.product}
              product_link={product.product_link}
              type={product.type}
              jaarlijkse_kosten={product.jaarlijkse_kosten}
              min_deposit={product.min_deposit}
              description={descript}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};
